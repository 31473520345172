<template>
  <div class="delivery-charges-app-root">
    <div class="px-spacing-none md:px-spacing-e pb-spacing-d sm:pb-spacing-e">
      <pc-page-transition>
        <router-view />
      </pc-page-transition>
    </div>
  </div>
</template>

<script>
import { PcPageTransition } from '@je-pc/ui-components'

export default {
  components: {
    PcPageTransition
  }
}
</script>
