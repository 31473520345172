var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h3',{staticClass:"font-extrabold lg:text-heading-s mb-spacing-b md:text-content-subdued"},[_vm._v(" "+_vm._s(_vm.$t('shift', { number: _vm.number }))+" ")]),_c('p',[_c('span',{staticClass:"text-body-l md:text-body-s lg:text-body-l",attrs:{"data-test":"order-price"},domProps:{"innerHTML":_vm._s(
        _vm.$t('minimum_order', {
          orderValue: _vm.$n(_vm.getMinimumOrder, 'currency'),
          deliveryValue: _vm.getDeliveryChargeText
        })
      )}}),_c('span',{staticClass:"font-bold"},[_vm._v(" "+_vm._s(_vm.$t('delivery_charge'))+" ")])]),_c('p',{staticClass:"mt-spacing-b text-body-l md:text-body-s lg:text-body-l"},[_c('span',{attrs:{"data-test":"bellow-minimum"},domProps:{"innerHTML":_vm._s(
        _vm.$t('below_minimum', {
          value: _vm.getSmallOrderChargeText
        })
      )}}),(_vm.getSmallOrderChargeValue)?_c('span',{staticClass:"font-bold"},[_vm._v(" "+_vm._s(_vm.$t('delivery_charge'))+" ")]):_vm._e()]),_c('p',{staticClass:"mt-spacing-b text-body-l md:text-body-s lg:text-body-l"},[(_vm.getFreeDeliveryValue)?_c('span',{attrs:{"data-test":"free-delivery-over"},domProps:{"innerHTML":_vm._s(
        _vm.$t('free_delivery_over', {
          value: _vm.$n(_vm.getFreeDeliveryValue, 'currency')
        })
      )}}):_c('span',{attrs:{"data-test":"free-delivery-over"},domProps:{"innerHTML":_vm._s(_vm.$t('free_delivery_over_off'))}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }