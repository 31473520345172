import Vue from 'vue'
import Vuex from 'vuex'

import {
  GET_DELIVERY_CHARGES,
  GET_SETTINGS,
  GET_SMALL_ORDER_FEE_ENABLED
} from './actions.types'
import {
  SET_DELIVERY_CHARGES,
  SET_SETTINGS,
  SET_SMALL_ORDER_FEE_ENABLED,
  SET_USER
} from './mutations.types'
import {
  getDeliveryCharges,
  getSettings,
  getSmallOrderFeeEnabled,
  setSmallOrderFeeEnabled
} from '../services/http'
import { trackDeliveryChargesView } from '../services/gtm'
import {
  IS_RDS_RESTAURANT,
  IS_SMALL_ORDER_FEE_ENABLED
} from '@/store/getters.types'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    deliveryCharges: [],
    smallOrderFeeEnabled: false,
    settings: {
      restaurantHasRdsSubscriptions: false
    },
    user: {}
  },
  getters: {
    [IS_RDS_RESTAURANT]: state => state.settings.restaurantHasRdsSubscriptions,
    [IS_SMALL_ORDER_FEE_ENABLED]: state => state.smallOrderFeeEnabled,
    getFormattedChargesList(state) {
      const list = state.deliveryCharges.reduce((list, item) => {
        list[item.zoneName] = [...(list[item.zoneName] || []), item]
        return list
      }, {})
      const preparedArray = Object.keys(list).map(key => ({
        zoneName: key,
        shiftsData: list[key]
      }))
      const index = preparedArray.findIndex(item => item.zoneName === 'Default')
      preparedArray.unshift(...preparedArray.splice(index, 1))
      return preparedArray
    }
  },
  mutations: {
    [SET_DELIVERY_CHARGES](state, data) {
      state.deliveryCharges = data
    },
    [SET_SMALL_ORDER_FEE_ENABLED](state, data) {
      state.smallOrderFeeEnabled = data
    },
    [SET_USER](state, user) {
      state.user = user
    },
    [SET_SETTINGS](state, settings) {
      state.settings = settings
    }
  },
  actions: {
    async [GET_DELIVERY_CHARGES]({ state, getters, commit }) {
      const response = await getDeliveryCharges()
      commit(SET_DELIVERY_CHARGES, response.data.deliveryFees)
      trackDeliveryChargesView(
        state.user?.restaurant?.id,
        getters.getFormattedChargesList
      )
    },
    async [GET_SMALL_ORDER_FEE_ENABLED]({ commit }) {
      const response = await getSmallOrderFeeEnabled()
      commit(SET_SMALL_ORDER_FEE_ENABLED, response.data.enabled)
    },
    async [GET_SETTINGS]({ commit }) {
      const response = await getSettings()
      commit(SET_SETTINGS, {
        restaurantHasRdsSubscriptions:
          response.data.settings.restaurantHasRdsSubscriptions === 'true'
      })
    },
    async [SET_SMALL_ORDER_FEE_ENABLED]({ commit }, enabled) {
      const response = await setSmallOrderFeeEnabled(enabled)
      if (response.status === 204) {
        commit(SET_SMALL_ORDER_FEE_ENABLED, enabled)
      }
    }
  }
})
