import Vue from 'vue'
import VueI18n from 'vue-i18n'
import uk from './uk'

Vue.use(VueI18n)

const Locales = {
  uk: 'en-Gb',
  ie: 'en-Ie',
  au: 'en-Au',
  nz: 'en-Nz',
  es: 'es-Es',
  it: 'it-It',
}

const messages = {
  [Locales.uk]: uk.messages
}

const numberFormats = {
  [Locales.uk]: uk.numberFormat
}

const i18n = new VueI18n({
  locale: Locales.uk,
  messages,
  numberFormats,
  fallbackLocale: Locales.uk
})

export default i18n
