<template>
  <div
    class="md:flex rounded-radius-c bg-container-default md:shadow-elevation-01 md:mb-spacing-g
               py-spacing-d m-spacing-d sm:m-none px-spacing-d md:px-spacing-e lg:px-spacing-e md:py-spacing-e border-b md:border-0 announcement justify-between items-center border-color-default"
  >
    <div class="lg:grid mb-spacing-d sm:mb-none md:w-4/5">
      <h2
        class="text-heading-s lg:text-heading-m leading-tight font-extrabold mb-spacing-a sm:mb-none"
      >
        {{ $t('small_order_fee_header') }}
      </h2>
      <p class="lg:mt-spacing-b">
        <span class="text-xl md:text-base">
          {{ $t('small_order_fee_text_r1') }}
        </span>
      </p>
      <p>
        <span class="text-xl md:text-base">
          {{ $t('small_order_fee_text_r2') }}
        </span>
      </p>
    </div>
    <div>
      <f-button
        :buttonType="button.type"
        @click="changeIsSmallOrderFeeEnabled()"
        buttonSize="large"
        data-cy="btn-go-back"
      >
        {{ button.text }}
      </f-button>
    </div>
  </div>
</template>
<script>
import { SET_SMALL_ORDER_FEE_ENABLED } from '@/store/mutations.types'

export default {
  name: 'SmallOrderFeeBanner',

  props: {
    isSmallOrderFeeEnabled: {
      required: true,
      type: Boolean
    }
  },

  computed: {
    button() {
      return this.isSmallOrderFeeEnabled
        ? { type: 'outline', text: this.$t('opt_out') }
        : { type: 'primary', text: this.$t('opt_in') }
    }
  },

  methods: {
    async changeIsSmallOrderFeeEnabled() {
      await this.$store.dispatch(
        SET_SMALL_ORDER_FEE_ENABLED,
        !this.isSmallOrderFeeEnabled
      )
    }
  }
}
</script>
<style scoped>
@media (min-width: 640px) {
  .announcement {
    background-image: url('../assets/announcement-1-small.svg');
    background-position-x: 85%;
    background-position-y: 1.5rem;
    background-size: 12%;
    background-repeat: no-repeat;
  }
}
@media (max-width: 640px) {
  .announcement {
    background-image: url('../assets/announcement-1-small.svg');
    background-position-x: 95%;
    background-position-y: 110%;
    background-size: 122px 122px;
    background-repeat: no-repeat;
  }
}
</style>
