import { trackCustomEvent } from '@je-pc/tracking'

export const trackDeliveryChargesView = (restaurantId, deliveryChargesList) => {
  const optionsAvailabilities = deliveryChargesList.map(item => {
    const { shiftsData } = item
    let isFreeDeliveyMinimumEnabled = false
    let isBelowMinimumEnabled = false
    let isFreeDeliveryOverEnabled = false
    shiftsData.forEach(item => {
      if (item.deliveryCharge !== null && item.deliveryCharge.value === 0) {
        isFreeDeliveyMinimumEnabled = true
      }
      if (item.smallOrderCharge !== null && item.smallOrderCharge.value !== 0) {
        isBelowMinimumEnabled = true
      }
      if (item.freeDelivery !== null && item.freeDelivery.value !== 0) {
        isFreeDeliveryOverEnabled = true
      }
    })
    return {
      isFreeDeliveyMinimumEnabled,
      isBelowMinimumEnabled,
      isFreeDeliveryOverEnabled
    }
  })

  const getAvailableItemsCount = option =>
    optionsAvailabilities.filter(item => item[option]).length

  const trackingObject = {
    component: {
      name: 'deliveryCharges',
      type: 'list',
      id: restaurantId
    },
    list: {
      itemsType: 'deliveryPostcodes',
      counts: {
        total: deliveryChargesList.length,
        visible: deliveryChargesList.length,
        freeDeliveyMinimum: getAvailableItemsCount(
          'isFreeDeliveyMinimumEnabled'
        ),
        belowMinimum: getAvailableItemsCount('isBelowMinimumEnabled'),
        freeDeliveryOver: getAvailableItemsCount('isFreeDeliveryOverEnabled')
      }
    }
  }

  trackCustomEvent('list_view', trackingObject)
}
