<template>
  <div>
    <pc-collapse :header-text="getZoneName">
      <div
        class="md:flex md:rounded-radius-c bg-container-default md:shadow-elevation-01 md:mb-spacing-e
               py-spacing-e md:py-spacing-g px-spacing-e lg:px-spacing-g border-b md:border-0 border-color-default"
      >
        <div class="w-1/6 lg:w-1/4 hidden md:block">
          <h2
            class="text-heading-s lg:text-heading-m leading-tight font-extrabold w-1/2"
          >
            {{ getZoneName }}
          </h2>
        </div>
        <shift-info
          class="flex-1"
          v-if="getFirstShiftData"
          :number="1"
          :data="getFirstShiftData"
        />
        <div class="md:hidden border-color-default my-spacing-d border-b"></div>
        <shift-info
          class="flex-1"
          v-if="getSecondShiftData"
          :number="2"
          :data="getSecondShiftData"
        />
      </div>
    </pc-collapse>
  </div>
</template>

<script>
import { PcCollapse } from '@je-pc/ui-components'
import ShiftInfo from './ShiftInfo'

const DELIVERY_LAUNCH = 'DeliveryLunch'
const DELIVERY_NIGHT = 'DeliveryNight'
const DEFAULT_CHARGE = 'Default'

export default {
  components: {
    ShiftInfo,
    PcCollapse
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    getZoneName() {
      const { zoneName } = this.item
      return zoneName === DEFAULT_CHARGE ? this.$t('standard_charge') : zoneName
    },
    getFirstShiftData() {
      return this.getShiftData(DELIVERY_LAUNCH)
    },
    getSecondShiftData() {
      return this.getShiftData(DELIVERY_NIGHT)
    }
  },
  methods: {
    getShiftData(deliveyTime) {
      const data = this.item.shiftsData.find(item =>
        item.zoneId.includes(deliveyTime)
      )
      return data
    }
  }
}
</script>
