import Vue from 'vue'
import singleSpaVue from 'single-spa-vue'
import { pageTitle } from '@je-pc/ui-components'
import { subscribeToUserUpdates } from '@je-pc/auth'

Vue.mixin(pageTitle)
import App from './App.vue'
import router from './router'
import i18n from './i18n'
import store from './store'
import { SET_USER } from './store/mutations.types'
import './assets/tailwind.css'
import FButton from '@justeat/f-button'
import '@justeat/f-button/dist/f-button.css'

Vue.component('f-button', FButton)

const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    render(h) {
      return h(App, {
        props: {
          name: this.name,
          mountParcel: this.mountParcel,
          singleSpa: this.singleSpa
        }
      })
    },
    router,
    i18n,
    store
  }
})

let userSubscription = null

export const bootstrap = vueLifecycles.bootstrap

export const mount = prop => {
  userSubscription = subscribeToUserUpdates(user => {
    store.commit(SET_USER, user)
  })
  return vueLifecycles.mount(prop)
}

export const unmount = prop => {
  userSubscription?.unsubscribe()
  return vueLifecycles.unmount(prop)
}
