<template>
  <div :class="{ container: !isMobile }">
    <div class="px-spacing-d md:px-none mb-spacing-e">
      <h1
        class="leading-none mb-spacing-b font-extrabold text-heading-m lg:text-heading-l"
      >
        {{ pageTitle }}
      </h1>
      <p>
        {{ $t('change_delivery_charge') }}
        <pc-global-link
          :to="getSendMessageUrl"
          class="inline-block text-content-distinct underline hover:underline"
        >
          {{ $t('send_message') }}.
        </pc-global-link>
      </p>
    </div>
    <small-order-fee-banner
      v-if="!isLoading && !isRdsRestaurant"
      :is-small-order-fee-enabled="isSmallOrderFeeEnabled"
    />
    <skeleton-loader v-if="isLoading" />
    <delivery-charges-list v-else />
  </div>
</template>

<script>
import { PcGlobalLink } from '@je-pc/ui-components'
import {
  GET_DELIVERY_CHARGES,
  GET_SETTINGS,
  GET_SMALL_ORDER_FEE_ENABLED
} from '../store/actions.types'
import DeliveryChargesList from '../components/DeliveryChargesList'
import SkeletonLoader from '../components/SkeletonLoader.vue'
import { isMobile } from '@je-pc/ui-components'
import SmallOrderFeeBanner from '@/components/SmallOrderFeeBanner.vue'
import { mapGetters } from 'vuex'
import {
  IS_RDS_RESTAURANT,
  IS_SMALL_ORDER_FEE_ENABLED
} from '@/store/getters.types'

export default {
  components: {
    SmallOrderFeeBanner,
    PcGlobalLink,
    DeliveryChargesList,
    SkeletonLoader
  },
  mixins: [isMobile],
  title() {
    return this.$t('delivery_charges')
  },
  data: () => ({
    isLoading: false
  }),
  computed: {
    ...mapGetters({
      isRdsRestaurant: IS_RDS_RESTAURANT,
      isSmallOrderFeeEnabled: IS_SMALL_ORDER_FEE_ENABLED
    }),
    getSendMessageUrl() {
      return `/help/send-message?referer=${this.$router.options.base}&preselected=delivery_areas___charges`
    }
  },
  async created() {
    this.isLoading = true
    await this.$store.dispatch(GET_DELIVERY_CHARGES)
    await this.$store.dispatch(GET_SMALL_ORDER_FEE_ENABLED)
    await this.$store.dispatch(GET_SETTINGS)
    this.isLoading = false
  }
}
</script>

<style scoped>
.delivery-icon {
  top: -4rem;
}
</style>
