// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/announcement-1-small.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "@media (min-width:640px){.announcement[data-v-148bde04]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position-x:85%;background-position-y:1.5rem;background-size:12%;background-repeat:no-repeat}}@media (max-width:640px){.announcement[data-v-148bde04]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position-x:95%;background-position-y:110%;background-size:122px 122px;background-repeat:no-repeat}}", ""]);
// Exports
module.exports = exports;
