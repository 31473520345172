<template>
  <div>
    <delivery-charge-item
      v-for="item in getFormattedChargesList"
      :key="item.zoneName"
      :item="item"
      data-test="delivery-charge-item"
    />
    <pc-back-to-top-button class="lg:pr-spacing-2i" :text="$t('back_to_top')" />
  </div>
</template>

<script>
import { PcBackToTopButton } from '@je-pc/ui-components'
import DeliveryChargeItem from './DeliveryChargeItem'

export default {
  components: {
    DeliveryChargeItem,
    PcBackToTopButton
  },
  computed: {
    getFormattedChargesList() {
      return this.$store.getters.getFormattedChargesList
    }
  }
}
</script>
