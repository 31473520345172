<template>
  <div>
    <div
      class="md:flex bg-container-default md:rounded-radius-c md:shadow-elevation-01
             md:mb-spacing-e py-spacing-c md:py-spacing-g px-spacing-d md:px-spacing-e lg:px-spacing-g border-b md:border-0 border-color-default"
      v-for="i in 5"
      :key="i"
    >
      <div v-if="isMobile">
        <skeleton-loader skeletonType="Heading" />
      </div>
      <div v-else class="flex w-full">
        <div class="w-1/6 lg:w-1/4">
          <skeleton-loader skeletonType="Heading" />
        </div>
        <div v-for="k in 2" :key="k" class="flex-1 pr-spacing-e">
          <skeleton-loader skeletonType="Heading" class="mb-spacing-a" />
          <skeleton-loader skeletonType="Sentence" />
          <skeleton-loader skeletonType="Sentence" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isMobile } from '@je-pc/ui-components'
import SkeletonLoader from '@justeat/f-skeleton-loader'

export default {
  components: { SkeletonLoader },
  mixins: [isMobile]
}
</script>

<style scoped>
@import '~@justeat/f-skeleton-loader/dist/f-skeleton-loader.css';
</style>
