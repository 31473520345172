import { ApiClient } from '@je-pc/api'
import { SMARTGATEWAY_URL } from '../constants'

const http = new ApiClient(SMARTGATEWAY_URL, true, true)

export const getDeliveryCharges = () => {
  return http.get(
    '/applications/partnerdeliverymanagement/{tenant}/{restaurantId}/deliveryfees'
  )
}

export const getSmallOrderFeeEnabled = () => {
  return http.get('/fees/{tenant}/partners/{restaurantId}/smallorderfee')
}

export const setSmallOrderFeeEnabled = async enabled => {
  return await http.patch(
    '/fees/{tenant}/partners/{restaurantId}/smallorderfee',
    {
      enabled: enabled
    }
  )
}

export const getSettings = () => {
  const settings = 'restaurantHasRdsSubscriptions'
  return http.get(
    '/applications/partnercentre/{tenant}/{restaurantId}/settings',
    {
      params: {
        settings: settings
      }
    }
  )
}
