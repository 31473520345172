<template>
  <div>
    <h3
      class="font-extrabold lg:text-heading-s mb-spacing-b md:text-content-subdued"
    >
      {{
        $t('shift', {
          number
        })
      }}
    </h3>
    <p>
      <span
        class="text-body-l md:text-body-s lg:text-body-l"
        data-test="order-price"
        v-html="
          $t('minimum_order', {
            orderValue: $n(getMinimumOrder, 'currency'),
            deliveryValue: getDeliveryChargeText
          })
        "
      ></span>
      <span class="font-bold">
        {{ $t('delivery_charge') }}
      </span>
    </p>
    <p class="mt-spacing-b text-body-l md:text-body-s lg:text-body-l">
      <span
        v-html="
          $t('below_minimum', {
            value: getSmallOrderChargeText
          })
        "
        data-test="bellow-minimum"
      ></span>
      <span v-if="getSmallOrderChargeValue" class="font-bold">
        {{ $t('delivery_charge') }}
      </span>
    </p>
    <p class="mt-spacing-b text-body-l md:text-body-s lg:text-body-l">
      <span
        data-test="free-delivery-over"
        v-if="getFreeDeliveryValue"
        v-html="
          $t('free_delivery_over', {
            value: $n(getFreeDeliveryValue, 'currency')
          })
        "
      ></span>
      <span
        v-html="$t('free_delivery_over_off')"
        v-else
        data-test="free-delivery-over"
      ></span>
    </p>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true
    },
    number: {
      type: Number,
      default: 0
    }
  },
  computed: {
    getMinimumOrder() {
      return this.data.minimumOrder?.value / 100
    },
    getDeliveryChargeValue() {
      return this.data.deliveryCharge?.value / 100
    },
    getSmallOrderChargeValue() {
      return this.data.smallOrderCharge?.value / 100
    },
    getFreeDeliveryValue() {
      return this.data.freeDelivery?.value / 100
    },
    getDeliveryChargeText() {
      return this.getDeliveryChargeValue === 0
        ? this.$t('free')
        : this.$n(this.getDeliveryChargeValue, 'currency')
    },
    getSmallOrderChargeText() {
      return this.getSmallOrderChargeValue
        ? this.$n(this.getSmallOrderChargeValue, 'currency')
        : this.$t('off')
    }
  }
}
</script>
